.chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 90%;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  background-color: #f9f9f9;
}

.messages-container {
  flex-grow: 1;
  overflow-y: auto;
  padding: 8px;
  border-bottom: 1px solid #ccc;
}


.avatar-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 2px solid transparent;
  cursor: pointer;
}

.avatar.selected {
  border-color: #099dfd;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #099dfd;
  color: white;
  cursor: pointer;
}

button:hover {
  background-color: #1171b1;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.loader {
  border: 16px solid #f3f3f3;
  /* Light grey */
  border-top: 16px solid #099dfd;
  /* Green */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.error-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.error-image {
  width: 150px;
  height: 150px;
  margin-bottom: 10px;
}

.error-message {
  font-size: 18px;
  color: red;
  text-align: center;
}

.video-grid {
  display: grid;
  height: 100vh;
  width: 100vw;
}

.video-grid-1 {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.video-grid-2 {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
}

.video-grid-3,
.video-grid-4 {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}

.video-grid-5,
.video-grid-6 {
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}

.video-grid-7,
.video-grid-8,
.video-grid-9 {
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
}

.video-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.full-screen-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.video-pane {
  width: 100%;
  background-color: #f0f0f0;
  /* Light gray background for visibility */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
}


.left-pane {
  width: 70%;
  background-color: #f0f0f0;
  /* Light gray background for visibility */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
}

.right-pane {
  width: 30%;
  background-color: #d0d0d0;
  /* Slightly darker gray background for visibility */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
}




.chat-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px 0;
  padding: 10px;
  max-width: 60%;
  border-radius: 10px;
  background-color: #f1f1f1;
}

.chat-item.current-user {
  align-self: flex-end;
  background-color: #8ddbff;
  align-items: flex-end;
  margin-left: auto;
}

.user-id {
  font-size: 0.75rem;
  color: gray;
  margin-right: 10px;
}

.message-text {
  font-size: 1rem;
  margin-bottom: 5px;
}

.timestamp {
  font-size: 0.75rem;
  color: gray;
}




.control-buttons {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  margin-top: 30px;
  background-color: #f1f1f1;
}


/* change the be */
.video-container {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  padding: 10px;
  background-color: #000;
  overflow: auto;
}

.video-container-grid {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  padding: 10px;
  background-color: #000;
  overflow: auto;
}


.video-container.spotlight .spotlight {
  flex-direction: column;
  flex: 2;
}

.video-container.spotlight .side-video {
  flex: 1;
  max-width: 200px;
}


.mode-switch {
  display: flex;
  justify-content: center;
  padding: 10px;
  background-color: #f1f1f1;
}

.video-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 2px;
  margin: 3px;
  border-radius: 5px;
}

.video-component video {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.video-component p {
  margin-top: 5px;
  font-size: 0.8rem;
  color: #333;
}

.video-alt-container img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  display: block;
  margin: auto;
  /* Center the image if it's smaller than the container */
}

.dropdown-container {
  margin: 20px;
  font-family: Arial, sans-serif;
}

label {
  margin-right: 10px;
  font-weight: bold;
}

select {
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
}

select:focus {
  outline: none;
  border-color: #099dfd;
}


option:hover {
  background-color: #099dfd;
}

.transcript-subtitle {
  font-family: Arial, sans-serif;
  /* Choose a clean, readable font */
  font-size: 16px;
  /* Adjust font size as needed */
  color: white;
  /* Text color */
  background-color: rgba(0, 0, 0, 0.3);
  margin-top: 3px;
  padding: 5px 15px;
  border-radius: 5px;
}

.transcript-container {
  height: 20%;
  overflow-x: auto;
  border: 3px solid #099dfd;
}

.transcript-message {
  font-size: medium;
  padding: 3px;
  font-family: 'Arial', sans-serif;
}

.transcript-message-user {
  font-weight: bold;
  padding: 10px 10px;
}

.agora-logo {
  margin: 50px;
  width: 80%;
}

.login-input-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  margin: 0 auto;
  padding: 40px;
  border-radius: 8px;
}

.login-button {
  padding: 15px 30px;
  font-size: 1.2rem;
  border: none;
  width: auto;
  border-radius: 30px;
  background-color: #099dfd;
  color: white;
  cursor: pointer;
}

input {
  padding: 15px 30px;
  font-size: 1.2rem;
  width: 40%;
  margin: 30px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.language-dropdown {
  padding: 15px 30px;
  font-size: 1.2rem;
  border-radius: 30px;
  border: 1px solid #ccc;
}

.login-screen {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 80vh;
  background-color: #f9f9f9;
  padding: 0 80px;
  margin: 100px;
  border-radius: 50px;
}

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
  color: #333;
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
}

.demo-header {
  display: flex;
  font-size: 2.5rem;
  width: 70%;
}

.login-background-img {
  z-index: -1;
  position: absolute;
  background-color: #0f6faf;
  width: 100%;
  top: 0;
  bottom: 0;
}

.transcript-pane {
  width: 40%;
  height: 100%;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
}

.video-meet {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

 .grid-video {
  width: 42%;
  margin: 2%;
}

